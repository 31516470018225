import React from 'react'

function Backend() {
  return (
    <div className="skills__content">
    <h3 className="skills__title"> Backend Development</h3>

    <div className="skills__box">
        <div className="skills__group">
            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">PHP</h3>
                    <div className="skills__level">
                        <div className="skill__meater" style={{width:"100px"}}></div>
                    </div>
                </div>
            </div>

            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">Java</h3>
                    <div className="skills__level">
                        <div className="skill__meater" style={{width:"100px"}}></div>
                    </div>
                </div>
            </div>

            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">Python</h3>
                    <div className="skills__level">
                        <div className="skill__meater" style={{width:"50px"}}></div>
                    </div>
                </div>
            </div>

            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">node Js</h3>
                    <div className="skills__level">
                        <div className="skill__meater" style={{width:"80px"}}></div>
                    </div>
                </div>
            </div>
        </div>    
        
        <div className="skills__group">
            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">My SQL</h3>
                    <div className="skills__level">
                        <div className="skill__meater" style={{width:"100px"}}></div>
                    </div>
                </div>
            </div>

            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">Laravel</h3>
                    <div className="skills__level">
                        <div className="skill__meater" style={{width:"50px"}}></div>
                    </div>
                </div>
            </div>

            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">MongoDB</h3>
                    <div className="skills__level">
                        <div className="skill__meater" style={{width:"90px"}}></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Backend